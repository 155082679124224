import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App, { ProtectedRoute } from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './pages/Login';
import RequestPasswordReset from './pages/PasswordReset/Request';
import PasswordReset from './pages/PasswordReset';
import Lite from './pages/Lite';
import LoggedIn from './components/LoggedIn';
import Dashboard from './pages/Dashboard';
import Memberships from './pages/Memberships';
import NewMembership from './pages/Memberships/NewMembership';
import MembershipDetail from './pages/Memberships/MembershipDetail';
import Patrons from './pages/Patrons';
import PatronDetail from './pages/Patrons/PatronDetail';
import Settings from './pages/Settings';
import Employees from './pages/Employees';
import Help from './pages/Help';
import MembershipSubscriptionsTable from './pages/Memberships/MembershipSubscriptionsTable';
import BenefitsView from './pages/Memberships/BenefitsView';
import ProfileForm from './pages/Patrons/ProfileForm';
import Integrations from './pages/Settings/Integrations';
import SalesAttributions from './pages/Employees/SalesAttributions';
import EmployeeDetail from './pages/Employees/EmployeeDetail';
import ManageEmployees from './pages/Employees/ManageEmployees';
import NewEmployee from './pages/Employees/NewEmployee';
require('dotenv').config()

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/password_reset/request",
        element: <RequestPasswordReset />,
      },
      {
        path: "/password_reset",
        element: <PasswordReset />,
      },
      {
        path: "/lite",
        element: <ProtectedRoute><Lite /></ProtectedRoute>,
      },
      {
        path: '/',
        element: <ProtectedRoute><LoggedIn /></ProtectedRoute>,
        children: [
          {
            path: '/',
            element: <Dashboard />,
          },
          {
            path: '/memberships/*',
            children: [
              {
                index: true,
                element: <Memberships />,
              },
              {
                path: 'new',
                element: <NewMembership />,
              },
              {
                path: ':id/*',
                element: <MembershipDetail />,
                children: [
                  {
                    index: true,
                    element: <MembershipSubscriptionsTable />
                  },
                  {
                    path: 'benefits',
                    element: <h1>BENNNS</h1>
                  }
                ]
              }
            ]
          },
          {
            path: '/patrons/*',
            children: [
              {
                index: true,
                element: <Patrons />,
              },
              {
                path: ':id/*',
                element: <PatronDetail />,
                children: [
                  {
                    index: true,
                    element: <ProfileForm />
                  },
                  {
                    path: 'benefits',
                    element: <BenefitsView />
                  }
                ]
              },
            ]
          },
          {
            path: '/settings/*',
            element: <Settings />,
            children: [
              {
                index: true,
                element: <Integrations />,
              }
            ]
          },
          {
            path: '/employees/*',
            element: <Employees />,
            children: [
              {
                index: true,
                element: <ManageEmployees />
              },
              {
                path: 'sales_attributions',
                element: <SalesAttributions />
              },
              {
                path: 'new',
                element: <NewEmployee />
              },
              {
                path: ':id',
                element: <EmployeeDetail />
              },
            ]
          },
          {
            path: '/help',
            element: <Help />,
          },
        ]
      }
    ]
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
